import { useUserRegistryRemoteInstance } from '@soomo/lib/hooks';
import { FC, isValidElement, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { CircularLoader, ErrorMessage, Header, Layout, SelectorPanel } from '../../components';
import { API_HOST } from '../../constants';
import { useFullStoryInitialize } from '../../hooks/useFullStoryInitialize';
import useIntercom from '../../hooks/useIntercom';
import KeyboardNavigation from '../../navigation/KeyboardNavigation';
import { RootState } from '../../store';
import { WelcomeContext } from '../../pages/Welcome';
import {
	fetchCurrentUserData,
	fetchInitialNotebookAsyncState,
	setCourseId,
	setTocOpen,
} from '../../store/actions';
import styles, { loaderTextStyles } from './styles';
import { postInteractionEvent } from 'api';
import { getParamsFromURL } from '@soomo/lib/notebook/utils';

type ProgressAppProps = ProgressAppReduxProps;

const screenMapping = {
	timing: 'time_overview',
	score: 'score_overview',
	grades: 'gradebook_points',
	progress: 'progress_overview',
};

const ProgressApp: FC<ProgressAppProps> = ({
	accessToken,
	courseId,
	tocOpen,
	currentUser,
	initialState,
	setCourseId,
	setTocOpen,
	fetchCurrentUserData,
	fetchInitialNotebookAsyncState,
	children,
}) => {
	const { fullstoryEnabled, userData, isUserLoading, userError } = currentUser;
	const { notebookSummary, isInitialStateLoading, initialStateError } = initialState;

	const { isLoading: registryLoading, userRegistry } = useUserRegistryRemoteInstance({
		apiHost: API_HOST,
		token: accessToken,
	});

	useEffect(() => {
		if (userData || isUserLoading || userError) return;
		fetchCurrentUserData();
	}, [userData, isUserLoading, userError, fetchCurrentUserData]);

	useFullStoryInitialize(userData, fullstoryEnabled);
	useIntercom(userData);

	useEffect(() => {
		if (!courseId || notebookSummary || isInitialStateLoading || initialStateError) return;
		fetchInitialNotebookAsyncState(null);
	}, [
		courseId,
		notebookSummary,
		isInitialStateLoading,
		initialStateError,
		fetchInitialNotebookAsyncState,
	]);

	const isLoading = isUserLoading || isInitialStateLoading || registryLoading;
	const error = userError || initialStateError;

	const locationPath = useLocation().pathname;
	const { courseId: paramsCourseId } = useParams<{ courseId?: string }>();
	const isParamsCourseIdValid = paramsCourseId && Number.isInteger(+paramsCourseId);

	const params = getParamsFromURL();

	useEffect(() => {
		if (!courseId) return;
		if (locationPath.endsWith('welcome')) {
			postInteractionEvent({
				screen: 'welcome',
				action_name: 'view',
				course_id: courseId,
			});
		} else if (locationPath.endsWith('page_view')) {
			postInteractionEvent({
				screen: 'page_detail',
				action_name: 'view',
				page_family_id: params.page_id,
				course_id: courseId,
			});
		} else if (locationPath.endsWith('overview')) {
			const view = params.view;
			if (view == null) {
				return;
			}
			postInteractionEvent({
				screen: screenMapping[view],
				action_name: 'view',
				course_id: courseId,
			});
		}
	}, [courseId, locationPath, params.page_id, params.view]);

	useEffect(() => {
		if (isParamsCourseIdValid && !courseId) {
			setCourseId(+paramsCourseId);
		}
	}, [courseId, isParamsCourseIdValid, paramsCourseId, setCourseId]);

	if (!isParamsCourseIdValid && !courseId) {
		return <Redirect to="/error/course-id-missing" />;
	}

	/**
	 * Welcome screen has a custom loading & error visual handling,
	 * so just immediately return a component with the loading state embedded
	 */
	if (locationPath.includes('welcome') && isValidElement(children) && userRegistry) {
		return (
			<WelcomeContext.Provider value={{ isAppLoading: isLoading, appError: error }}>
				<div className={styles}>{children}</div>
			</WelcomeContext.Provider>
		);
	}

	if (isLoading) {
		return <CircularLoader textClassName={loaderTextStyles} />;
	}

	if (error) {
		return (
			<ErrorMessage title="Site content cannot be loaded right now">{error}</ErrorMessage>
		);
	}

	return notebookSummary && userData ? (
		<>
			<KeyboardNavigation />
			<div className={styles}>
				<Header />
				<div id="app-body">
					<SelectorPanel />
					<Layout onClick={() => tocOpen && setTocOpen(false)}>{children}</Layout>
				</div>
			</div>
		</>
	) : null;
};

const mapStateToProps = (state: RootState) => ({
	accessToken: state.accessToken,
	courseId: state.courseId,
	tocOpen: state.tocOpen,
	currentUser: {
		userId: state.userId,
		userData: state.user,
		fullstoryEnabled: state.fullstoryEnabled,
		isUserLoading: state.async.currentUserData,
		userError: state.errors.currentUserData,
	},
	initialState: {
		notebookSummary: state.notebookSummary,
		isInitialStateLoading: state.async.initialState,
		initialStateError: state.errors.initialState,
	},
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setCourseId,
			setTocOpen,
			fetchCurrentUserData: fetchCurrentUserData.request,
			fetchInitialNotebookAsyncState: fetchInitialNotebookAsyncState.request,
		},
		dispatch
	);

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProgressAppReduxProps = ConnectedProps<typeof connector>;

export default connector(ProgressApp);
