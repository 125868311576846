import React from 'react';
import {
	ElementAnswer,
	MCQuestionAnswer,
	MCQuestionPoolAnswer,
	PollQuestionAnswer,
	UserDecisions,
} from '@soomo/lib/notebook/types';
import { CoursePolicy, ExternalToolElement } from '@soomo/lib/types/WebtextManifest';
import {
	DecisionElement,
	Manifest,
	MCQuestionElement,
	MCQuestionPoolElement,
	Page,
	BasePageElement,
	PollQuestionElement,
	PopupQuizElement,
	ResponseBoardElement,
	SAQuestionElement,
	VoiceRecordingElement as LibsVoiceRecordingElement,
	WritingTemplateElement,
} from '@soomo/lib/types/WebtextManifest';
import { getPolicyForPage, getPolicyForRespondable } from '@soomo/lib/utils';
import { Course, VoiceRecordingAnswer, SAQuestionAnswer, ResponseBoardAnswer } from 'types';
import {
	DecisionPoint,
	MCQuestion,
	PollQuestion,
	PopUpQuiz,
	ResponseBoard,
	SAQuestion,
	VoiceRecording,
	WritingTemplate,
} from 'components/page_view/elements';
import ExternalTool from './elements/ExternalTool';
import { isReviewTemplate } from '@soomo/lib/components/WritingTemplate/WritingTemplate/utils';
import { getBuilderConfig } from './elements/WritingTemplate';
import { GoReactElement } from '@soomo/lib/components/pageElements/GoReact/types';
import { getPageElementSetting } from '../../utils/manifest';
import MCQuestionPool from './elements/McQuestionPool';

interface VoiceRecordingElement extends LibsVoiceRecordingElement {
	autosubmit: boolean;
}

interface Props {
	correctChoiceFamilyId: string;
	userDecisions?: UserDecisions; // Array of interactions with respondable of toc family_id
	answers: ElementAnswer[];
	page: Page;
	element: BasePageElement;
	manifest: Manifest;
	course: Course;
	coursePolicy: CoursePolicy;
	elementActivity: any;
}

const NotebookPageElement = (props: Props) => {
	const {
		correctChoiceFamilyId,
		element,
		page,
		answers,
		userDecisions,
		manifest,
		course,
		coursePolicy,
		elementActivity,
	} = props;
	const answer = answers && answers.length > 0 ? answers[0] : null;
	const pagePolicy = getPolicyForPage(coursePolicy, page.id);
	const elementPolicy = getPolicyForRespondable(
		coursePolicy,
		element.ruby_class,
		element.family_id
	);

	let component = null;
	// todo: Connect these with redux
	switch (element.type) {
		case 'mc_question':
			component = (
				<MCQuestion
					label={pagePolicy.mc_question_type_label}
					element={element as MCQuestionElement}
					answers={answers as Array<MCQuestionAnswer>}
					elementActivity={elementActivity}
					correctChoiceFamilyId={correctChoiceFamilyId}
				/>
			);
			break;
		case 'mc_question_pool':
			component = (
				<MCQuestionPool
					label={pagePolicy.mc_question_type_label}
					element={element as MCQuestionPoolElement}
					answers={answers as unknown as Array<MCQuestionPoolAnswer>}
					elementActivity={elementActivity}
					attemptsAllowed={elementPolicy.attempts_allowed}
					correctChoiceFamilyId={correctChoiceFamilyId}
				/>
			);
			break;
		case 'sa_question':
			component = (
				<SAQuestion
					element={element as SAQuestionElement}
					elementActivity={elementActivity}
					answer={answer as SAQuestionAnswer}
				/>
			);
			break;
		case 'poll_question':
			component = (
				<PollQuestion
					element={element as PollQuestionElement}
					elementActivity={elementActivity}
					answer={answer as PollQuestionAnswer}
					tabsUi={getPageElementSetting(manifest, page.page_type, 'Poll', 'tabs_ui')}
				/>
			);
			break;
		case 'response_board':
			component = (
				<ResponseBoard
					element={element as ResponseBoardElement}
					elementActivity={elementActivity}
					answer={answer as ResponseBoardAnswer}
				/>
			);
			break;
		case 'decision':
			const decision = element as DecisionElement;
			component = (
				<DecisionPoint
					element={element as DecisionElement}
					decisionOptions={manifest.toc.config.course_decisions[decision.name].options}
					decision={decision ? userDecisions[decision.name] : null}
				/>
			);
			break;
		case 'self_assessment':
			component = <PopUpQuiz element={element as PopupQuizElement} answers={answers} />;
			break;
		case 'voice_recording':
			component = (
				<VoiceRecording
					course={course}
					element={element as VoiceRecordingElement}
					elementActivity={elementActivity}
					answer={answer as VoiceRecordingAnswer}
				/>
			);
			break;
		case 'interactive_template':
			component = (
				<WritingTemplate
					element={element as WritingTemplateElement}
					elementActivity={elementActivity}
					manifest={manifest}
					webtextBuilderData={(answer as any) || {}}
					userDecisions={userDecisions}
				/>
			);
			break;
		case 'external_tool':
			component = <ExternalTool element={element as ExternalToolElement} answer={answer} />;
			break;
	}
	return component ? <div className="notebook-page-element">{component}</div> : null;
};

export const getShouldRenderElement = (
	element: BasePageElement,
	userDecisions: UserDecisions
) => {
	switch (element.type) {
		case 'interactive_template': {
			const writingTemplateElement = element as WritingTemplateElement;
			return !isReviewTemplate(getBuilderConfig(writingTemplateElement, userDecisions));
		}
		case 'external_tool': {
			const externalToolElement = element as ExternalToolElement;
			const goReactElement = externalToolElement as unknown as GoReactElement;
			return (
				'score_config' in goReactElement &&
				goReactElement?.score_config?.scoreOptions !== 'noPoints'
			);
		}
		default:
			return true;
	}
};

export default NotebookPageElement;
