import { ElementsConfig, Manifest, Page } from '@soomo/lib/types/WebtextManifest';

export const getPageConfig = (manifest: Manifest, pageType: Page['page_type']) =>
	manifest.toc.config.pages.types[pageType];

export const getPageElementConfig = (
	manifest: Manifest,
	pageElementType: string,
	elementType: keyof ElementsConfig
) => getPageConfig(manifest, pageElementType)?.elements?.[elementType];

export const getPageElementSetting = (
	manifest: Manifest,
	pageElementType: string,
	elementType: keyof ElementsConfig,
	setting: keyof ElementsConfig[string]['settings']
) => getPageElementConfig(manifest, pageElementType, elementType)?.settings?.[setting];
